<!--
 * @Description:待确认工单详情-问题确认 waitConfirmWorkConfirm
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-26 09:54:59
 * @LastEditors: linf
-->
<template>
  <div class='waitConfirmWorkConfirm-mainbody'>
    <van-nav-bar title="问题确认"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickBack" />
    <van-row class="lineRow">
      <van-row class="lineRow-Pic">
        <van-uploader upload-text='拍照/上传照片'
                      v-model="fileList"
                      max-count="1"
                      :after-read='afterRead'
                      preview-size='101' />
      </van-row>
      <van-field class="lineField"
                 v-model="ticketConfirmRemark"
                 rows="2"
                 autosize
                 type="textarea"
                 maxlength="50"
                 placeholder="确认备注"
                 show-word-limit />
    </van-row>
    <van-row class="bottom">
      <van-row class="bottom-button-leftDiv">
        <van-button class="bottom-button-left"
                    @click="handleClickSelfSolve">自行处理</van-button>
      </van-row>
      <van-row class="bottom-buttonDiv">
        <van-button class="bottom-button"
                    @click="handleClickOptionSolve">运维处理</van-button>
      </van-row>
    </van-row>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Compressor from 'compressorjs'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      ticketConfirmRemark: '', // 确认备注
      fileList: [], // 上传文件
      picUrl: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 短信验证
    equipmentRepair () {
      const info = {
        deptName: this.$route.query.deptName,
        deviceType: this.$route.query.deviceType,
        deviceId: this.$route.query.deviceId,
        questionType: this.$route.query.questionType,
        deviceAddress: this.$route.query.deviceAddress,
        phone: this.$route.query.phone
      }
      this.$waitConfirmWork.equipmentRepair(info).then(() => {
        this.$router.go(-2)
      })
    },
    // 点击自行解决
    handleClickSelfSolve () {
      this.$dialog.alert({
        title: '自行处理',
        message: '确认选择 自行处理',
        showCancelButton: true
      }).then(() => {
        if (!this.picUrl) {
          this.$toast('请上传图片')
        } else if (!this.ticketConfirmRemark) {
          this.$toast('请输入备注')
        } else if (this.ticketConfirmRemark && this.picUrl) {
          const info = {
            workTicketSequence: this.$route.query.workTicketSequence,
            ticketConfirmRemark: this.ticketConfirmRemark,
            picUrl: this.picUrl
          }
          this.$waitConfirmWork.confirmWorkTicket(info).then(() => {
            this.$router.go(-2)
          })
        }
      })
    },
    // 点击运维处理
    handleClickOptionSolve () {
      this.$dialog.alert({
        title: '运维处理',
        message: '确认选择 运维处理',
        showCancelButton: true
      }).then(() => {
        if (!this.picUrl) {
          this.$toast('请上传图片')
        } else if (!this.ticketConfirmRemark) {
          this.$toast('请输入备注')
        } else if (this.ticketConfirmRemark && this.picUrl) {
          const info = {
            workTicketSequence: this.$route.query.workTicketSequence,
            ticketConfirmRemark: this.ticketConfirmRemark,
            picUrl: this.picUrl
          }
          this.$waitConfirmWork.confirmWorkTicket(info).then(() => {
            this.equipmentRepair()
          })
        }
      })
    },
    // 上传 读取完成
    afterRead (file) {
      if (file instanceof Array) {
        file = file[0]
      }
      file.status = 'uploading'
      file.message = '上传中...'
      // 压缩图片
      const self = this
      /* eslint-disable no-new */
      new Compressor(file.file, {
        quality: 0.1,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            file.status = 'done'
            self.picUrl = res.resultEntity
          }).catch(err => {
            console.log('图片上失败', err)
            file.status = 'failed'
            file.message = '上传失败'
          })
        },
        error (err) {
          file.status = 'failed'
          file.message = '图片压缩失败'
          console.log('压缩失败', err)
        }
      })
    },
    // 顶部返回
    handleClickBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.waitConfirmWorkConfirm-mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .lineRow {
    width: 100%;
    height: 40%;
    margin-top: 12px;
    background: #ffffff;
    .lineRow-Pic {
      height: 45%;
      padding-left: 3.2%;
      padding-top: 3.2%;
    }
    .lineField {
      height: 50%;
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: space-around;
    font-family: PingFang-SC-Regular;
    position: absolute;
    bottom: 20px;
    padding-left: 3%;
    .bottom-button-leftDiv {
      width: 40%;
      .bottom-button-left {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background: #fffefe;
        font-size: 15px;
        color: #19a9fc;
      }
    }
    .bottom-buttonDiv {
      width: 40%;
      .bottom-button {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background: #19a9fc;
        font-size: 15px;
        color: #fffefe;
      }
    }
  }
}
</style>
